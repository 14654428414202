export default class InputGetProperties
{
  constructor(pageSize, pageNumber, orderBy, propertyName, searchText, active ) 
  {
    this.pageSize = pageSize;
    this.pageNumber = pageNumber;
    this.orderBy = orderBy;
    this.propertyName = propertyName;
    this.searchText = searchText;
    this.active = active;
  }    
}