<template>
  <div class="py-5">
    <!-- Status picker and add property -->
    <div class="d-flex justify-content-between flex-wrap">
      <div class="col-lg-6 m-0 p-0 text-left"> 
        <button v-bind:class="{'font-weight-bold': status == ACTIVE_STATUS}" class="status-button" @click="status = ACTIVE_STATUS">Ativos</button> <span  v-bind:class="{'font-weight-bold': status == ACTIVE_STATUS}">({{activeCount}})</span> | <button v-bind:class="{'font-weight-bold': status == DISABLED_STATUS}" class="status-button" href="#" @click="status = DISABLED_STATUS">Arquivo</button> <span v-bind:class="{'font-weight-bold': status == DISABLED_STATUS}">({{disabledCount}})</span>
      </div>
      <button id="add-button" type="button" class="btn color-button text-light d-flex align-items-center" @click="addProperty">
        <span class="d-none d-lg-block">Adicionar Imóvel</span>
        <span class="d-block d-lg-none font-weight-bold">+</span>
      </button>
    </div>
    <!-- Search bar and filter/order dropdowns -->
    <div class="d-flex align-items-center flex-wrap my-4">
      <div class="col-12 col-lg-6">
        <SearchBar @search-clicked="searchClicked"></SearchBar>
      </div>
      <div class="d-flex col-12 col-lg-6 justify-content-end dropdown-container my-3 my-lg-0">
        <select v-model="sorterBy" class="form-control cursor-pointer">
          <option v-if="!sorterBy" value="0" selected="selected" disabled="disabled" hidden="hidden">Ordenar por</option> 
          <option v-else value="0">Ordenação por defeito</option> 
          <option value="1">Nome: A a Z</option>
          <option value="2">Nome: Z a A</option>
          <option value="3">Localização: A a Z</option>
          <option value="4">Localização: Z a A</option>
        </select>
        <select v-model="filterBy" class="form-control cursor-pointer">
          <option v-if="!filterBy" value="0" selected="selected" disabled="disabled" hidden="hidden">Filtrar por</option> 
          <option v-else value="0">Sem filtro</option> 
          <option value="1">Titular Empresa</option>
          <option value="2">Titular Pessoal</option>
        </select>
      </div>
    </div>
    <!-- List: this will have different UI on mobile -->
    <CTableWrapper ref="table" hover striped :caption="caption" :items="items" :actions="actions" :pages="pages" :fields="fields" clickable-rows @row-clicked="rowClicked" @action-clicked="actionClicked" @update-requested="updateItemsRequested" :sorter='{ external: false, resetable: false }' :loading="loading" paginationSubtitle="Imóveis por Página">
    </CTableWrapper>
  </div>
</template>
<script>
import SearchBar from '@/controls/SearchBar.vue'
import { mapState, mapActions } from 'vuex'
import CTableWrapper from '@/controls/PaginatedTable.vue'
import router from '@/router';
import InputGetProperties from "@/classes/InputGetProperties";
import { UTILS_HELPER } from "@/helpers/utils.js";
import EventBus from '@/events/bus.js';

const ASC = 1;
const DESC = 2;

const SORTERS = 
[
  {column: 'id', by: DESC},
  {column: 'name', by: ASC},
  {column: 'name', by: DESC},
  {column: 'address', by: ASC},
  {column: 'address', by: DESC}
];

const FILTERS = 
[
  {column: null, value: null},
  {column: 'ownership', value: 'Business'},
  {column: 'ownership', value: 'Personal'}
];

const DISABLED_STATUS = false;
const ACTIVE_STATUS = true;

export default {
  name: "PropertiesList",
  components: { CTableWrapper, SearchBar },
  data () {
    return {
      status: ACTIVE_STATUS,
      caption: ('Imóveis'),
      currentPage: 1,
      loading: false,
      itemsPerPage: localStorage.itemsPerPage ?? 10,
      fields: [
        { key: 'name', label: ('Nome'), _classes: 'font-weight-bold', sortable: false },
        { key: 'address', label: ('Localização'), _classes: 'font-weight-bold', sortable: false },
        { key: 'liquidated', label: ('% Liquidada'), _classes: 'font-weight-bold', sortable: false},
        { key: 'status', label: ('Estado'), _classes: 'font-weight-bold', sortable: false},
        { key: 'readableOwnership', label: ('Titular'), _classes: 'font-weight-bold', sortable: false},
      ],      
      actions: [
        { key: 'view', image: 'iconRightArrowWhite.png', imageBig: 'iconRightArrow.png' },
        { key: 'disable', image: 'iconFolderWhite.png', imageBig: 'iconFolder.png' }
      ],
      sorterBy: 0,
      filterBy: 0,
      // Provide access to consts on HTML
      DISABLED_STATUS: DISABLED_STATUS,
      ACTIVE_STATUS: ACTIVE_STATUS
    }
  },
  computed: {
    ...mapState('properties', ['listOutput']),
    ...mapState('properties', ['processTask']),
    propertiesInfo: function() {
      return this.listOutput && this.listOutput.data ? this.listOutput.data : null;
    },
    items: function()
    {
      var result = [];

     if(this.propertiesInfo && this.propertiesInfo.properties)
      {
          result = this.propertiesInfo.properties;

          result.forEach(item => {
              item["purchaseDate"] = UTILS_HELPER.formatDate(item.purchaseDate);
              item["readableOwnership"] = item.ownership == "Personal" ? "Pessoal" : "Empresa";

              const percentLiquid = (item.accountability * 100) / item.totalFinancing;
              item["liquidated"] = `${percentLiquid.toFixed(2)}%`;

              // Build status, accommodating to the fact that there may be multiple modules
              var modules = item.rented + item.airBnB + item.noIncome;
              if(modules == 1)
              {
                item["status"] = item.rented ? "Arrendado" : (item.airBnB ? "AirBnb (ou semelhante)" : "Sem rendimento");
              }
              else
              {
                const statusParts = [];
                if(item.rented) statusParts.push(`${item.rented} Arrendado(s)`);
                if(item.airBnB) statusParts.push(`${item.airBnB} AirBnb (ou semelhante)`);
                if(item.noIncome) statusParts.push(`${item.noIncome} Sem rendimento`);

                item["status"] = statusParts.join(", ");
              }
          });
      }

      return result;
    },
    activeCount: function()
    {
      return this.propertiesInfo ? this.propertiesInfo.active : 0;
    },
    disabledCount: function()
    {
      return this.propertiesInfo ? this.propertiesInfo.inactive : 0
    },
    pages: function()
    {
      return this.listOutput ? this.listOutput.totalPages : 1;
    },
  },
  mounted: function()
  {
    // Active have default value of true
    this.updateItemsRequested(1, this.itemsPerPage);
  },
  methods: {
    ...mapActions('properties', ['getList']),
    ...mapActions('properties', ['updateStatus']),
    rowClicked (item) {
      this.$router.push({name: "ViewProperty", params: { property: item } });
    },
    actionClicked(item, action){
      switch(action.key)
      {
        case 'view':
        {
          this.$router.push({name: "ViewProperty", params: { property: item } });
          break;
        }
        case 'disable':
        {
          EventBus.$emit("warning-alert-requested", { message: `Tem a certeza que pretende arquivar o imóvel '${item.name}'?`, primaryCallback: () =>
          {
            this.loading = true;
            this.updateStatus(item.id);
          }, secondaryButton: "Cancelar"});
          break;
        }
        case 'enable':
        {
          EventBus.$emit("warning-alert-requested", { message: `Tem a certeza que pretende ativar o imóvel '${item.name}'?`, primaryCallback: () =>
          {
            this.loading = true;
            this.updateStatus(item.id);
          }, secondaryButton: "Cancelar"});
          break;
        }
        default:
        {
          throw "The provided action is not supported!"; 
        }
      }
    },
    updateItemsRequested(currentPage, itemsPerPage)
    {
      this.loading = true;
      
      //Make request
      this.currentPage = currentPage;
      this.itemsPerPage = itemsPerPage;

      // Build sorter and filter objects
      const sorterValue = SORTERS[this.sorterBy];
      
      var input = new InputGetProperties(itemsPerPage, currentPage, sorterValue.by, UTILS_HELPER.convertToPascalCase(sorterValue.column), this.searchText, this.status);
      if(this.filterBy)
      {
        const filterValue = FILTERS[this.filterBy];
        input[filterValue.column] = filterValue.value;
      }

      this.getList(input); 
    },
    addProperty () 
    {
      router.push({ path: '/imoveis/adicionar' });
    },
    searchClicked(searchText)
    {
      this.searchText = searchText;
      this.$refs.table.reset();
      this.updateItemsRequested(1, this.itemsPerPage);
    },
    sorterValueChanged(value)
    {
      this.sorterValue = value;
    }
  },
  watch:
  {
    status: function (val) {
      // Update actions
      switch(val) {

        case ACTIVE_STATUS:
        {
          this.actions = [];        
          this.actions.push({ key: 'view', image: 'iconRightArrowWhite.png', imageBig: 'iconRightArrow.png' });
          this.actions.push({ key: 'disable', image: 'iconFolderWhite.png', imageBig: 'iconFolder.png'});
          
          break;
        }
        case DISABLED_STATUS:
        {
          this.actions = [];
        
          this.actions.push({ key: 'view', image: 'iconRightArrowWhite.png', imageBig: 'iconRightArrow.png' });
          this.actions.push({ key: 'enable', image: 'iconUnarchiveWhite.png', imageBig: 'iconUnarchive.png' });
          break;
        }
        default:
        {
          throw "The provided status is not supported!"; 
        }
      }

      // Update items
      this.$refs.table.reset();
      this.updateItemsRequested(1, this.itemsPerPage);
    },
    sorterBy: function()
    {
      this.updateItemsRequested(1, this.itemsPerPage);
    },
    filterBy: function()
    {
      this.updateItemsRequested(1, this.itemsPerPage);
    },
    listOutput: function()
    {
      this.loading = false;
    },
    processTask: function(val)
    {
      if(val !== null)
      {
        this.loading = false;
        this.updateItemsRequested(this.currentPage, this.itemsPerPage);
      }
    }
  }
}
</script>

 
<style>
th
{
  border-top: none !important;
  color: white;
  background-color: rgb(143, 148, 251) !important; 
}

td
{
  color: rgb(143, 148, 251);
  font-weight: bold;
}

tr
{
  cursor: pointer;
}

.status-button
{
  cursor: pointer;
  border: none;
  padding: 0;
  text-decoration: none;
  color: rgb(143, 148, 251);
  font-size: 25px;
}

.color-button{
  cursor: pointer;
  background-color: rgb(78, 84, 200);
  border-radius: 15px;
}

.dropdown-container
{
  gap: 8px;
}

#add-button:hover span
{
  color: whitesmoke;
}

@media only screen and (max-width: 991px) {
  #add-button
  {
    width: 50px;
    height: 50px;
    border-radius: 50px;
    position: relative;
  }
  #add-button > span
  {
    font-size: 28px;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    left: 0;
    right: 0;
  }
}

</style> 